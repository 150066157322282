<div class="background-gradient" *ngIf="!loading">
  <div class="left-rect"></div>
  <div class="right-rect"></div>
</div>
<div class="nf-icon-container" *ngIf="!loading && brandingUpdated"><div class="nf-icon"></div></div>
<div class="private-key-page" *ngIf="!loading">
  <div class="private-key-form">
    <div *ngIf="!isSuccessful">
    <div class="private-key-header">
      <div class="private-key-icon"></div>
      <div class="private-key-title-container">
        <div class="private-key-form-title">
          Add your private key below
        </div>
        <div class="private-key-form-subtitle">
          Use the field below to copy and paste your SSH key
        </div>
      </div>
    </div>
    <div class="private-key-form-content">
      <textarea
        [(ngModel)]="value"
        id="NewStoredSecretValue"
        (change)="validate()"
        [ngClass]="{'ng-invalid': !isValid}"
        placeholder="Paste your key here."
      ></textarea>
      <span [hidden]="!errors['value']" class="err">
        {{ errors['value'] }}
      </span>
      <span [hidden]="!errors['token']" class="err">
          {{ errors['token'] }}
      </span>
    </div>
    <div class="private-key-form-buttons">
      <button (click)="submitStoredSecret()" class="save confirm" label="Save" >Add Key</button>
    </div>
  </div>
    <div *ngIf="isSuccessful" class="private-key-header-success">
      <div class="private-key-title-container">
        <div style="text-align: center;" class="private-key-form-title">
          Update Successful
        </div>
        <div class="private-key-form-subtitle">
          Your private key was updated successfully
        </div>
        <div style="text-align: center; padding-top: 0.9375rem;">
          <span class="pi pi-check-circle" style="font-size: 2.0rem; color: #00db48; align: center;"></span>
        </div>
        <div class="private-key-form-content">
          You may now close the window.
        </div>
  </div>
  </div>
</div>
<app-loader *ngIf="loading" [isLoading]="loading"></app-loader>
