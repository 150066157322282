<div class="fullModal opened" id="ZitiEndpointAddModal">
    <div [hidden]="isComplete" class="fullRow">
        <div class="title" style="filter: brightness(50%)">
            <span *ngIf="model.id">Edit Your Notification</span>
            <span *ngIf="!model.id">Create A New Notification</span>
        </div>
        <div *ngIf="model.id" class="subtitle">Update your notification attributes below</div>
        <div *ngIf="!model.id" class="subtitlealt">
            Get notified for specific network events by creating a notification subscription below. Just select an event
            type, the network it applies to, and where you would like to receive notifications.
        </div>
        <div class="sized">
            <div class="label row">Notification Name<span class="note">Required</span></div>
            <div class="row">
                <input
                    (keyup.enter)="save()"
                    [(ngModel)]="model.name"
                    [disabled]="model.id && !canEdit"
                    [ngClass]="{ error: errors['name'] }"
                    id="EditZRouterName"
                    minlength="5"
                    placeholder="What would you like to call this notification?"
                    type="text"
                />
            </div>
            <!-- <div class="label row">Description
</div> -->
            <!-- <div class="row">
<input id="EditZRouterName" type="text" [disabled]="model.id && !canEdit"
placeholder="Provide a description for your notification." [(ngModel)]="model.description" (keyup.enter)="save()"
minlength="5" maxlength="64" />
</div> -->
            <div class="half">
                <div class="padRight">
                    <div class="label row">Notification Type<span class="note">Required</span></div>
                    <select [(ngModel)]="model.condition.type" [ngClass]="{ error: errors['type'] }">
                        <option value="">Select a Type</option>
                        <option value="Alarm">Alarm</option>
                    </select>
                </div>
            </div>
            <div class="half" id="NetworkField">
                <div class="label row">Network<span class="note">Required</span></div>
                <select
                    (change)="onChange($event)"
                    [ngClass]="{ error: errors['network'] }"
                    [ngModel]="networkId"
                    id="NetworkSelect"
                >
                    <option [ngValue]="undefined">Select A Network</option>
                    <option *ngFor="let network of combinedNetworks | sortby: 'name'" [value]="network.id">
                        {{ network.name }}
                    </option>
                </select>
            </div>
            <div class="label row">Destination Emails<span class="note">Required</span></div>
            <div class="row">
                <input
                    [(ngModel)]="emails"
                    id="EditZRouterName"
                    minlength="5"
                    placeholder="Emails must be separated by ',' and no spaces."
                    type="text"
                />
            </div>

            <div class="row buttonRow right" style="margin-top: 1.25rem">
                <button
                    (click)="save()"
                    *ngIf="!processing && (!model.id || canEdit)"
                    class="save"
                    id="SaveZitiEndpointButton"
                >
                    {{ model.id ? 'Update' : 'Create' }}
                </button>
                <button *ngIf="processing" class="save confirm" disabled>Saving <span class="spinner"></span></button>
            </div>
            <div (click)="hide()" class="buttonBall close" id="EscZEndpointClose">
                <div class="buttonLabel">ESC</div>
            </div>
        </div>
    </div>

    <div [hidden]="!isComplete" class="fullRow" id="Stage3">
        <div class="confetti">
            <!--confetti div starts-->
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>

            <div class="title congratulations" style="filter: brightness(50%)">Congratulations!</div>
            <div class="subtitlealt">
                Your notification has been created. You may edit it at anytime under manage notifications.
            </div>
            <div class="row center">
                <div class="congratsicon"></div>
            </div>
            <div class="row center" style="margin-top: 1.5625rem">
                <button (click)="closeDialog()" [routerLink]="Notifications" class="save">Manage Notifications</button>
            </div>
        </div>

        <div (click)="hide()" class="buttonBall close" id="EscZEndpointClose">
            <div class="buttonLabel">ESC</div>
        </div>
        <app-form-support-button *ngIf="!hideHelp"></app-form-support-button>
    </div>
    <!--confetti div ends-->
</div>
