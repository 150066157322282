<div class="modal solid open" id="ClientMfaModal">
    <app-loader [isLoading]="isLoading"></app-loader>
    <div (click)="cancel()" class="miniClose cancel icon-HamburgerAppClose"></div>
    <div [hidden]="isLoading">
        <div class="mfaModalIcon"></div>
        <div class="title" style="padding-left: 7.5rem; background-size: 10%; line-height: 4.0625rem">
            Client MFA Settings
        </div>
        <div class="subtitle">Enter Multi Factor Authentication Details</div>

        <div class="content">
            <div class="label row">
                Client ID<span [ngClass]="{ error: errorClientId, note: !errorClientId }">Required</span>
            </div>
            <div class="row">
                <input
                    [(ngModel)]="networkModel.mfaClientId"
                    [ngClass]="{ error: errorClientId }"
                    id="ClientId"
                    placeholder="Paste Client ID Here"
                />
            </div>
            <div class="label row">
                Issuer URL<span [ngClass]="{ error: errorIssuerUrl, note: !errorIssuerUrl }">Required</span>
            </div>
            <div class="row">
                <input
                    [(ngModel)]="networkModel.mfaIssuerId"
                    [ngClass]="{ error: errorIssuerUrl }"
                    id="IssuerURL"
                    placeholder="Paste Issuer URL"
                />
            </div>
        </div>
        <div class="buttons">
            <div (click)="openLink()" class="linkButton">Learn about MFA</div>
            <button (click)="clearAndSave()" class="save clear" id="ClearAndSaveClientMfaDetails">
                Clear and Save
            </button>
            <button (click)="save()" *ngIf="!processing" class="save confirm" id="SaveClientMfaDetails">Save</button>
            <button *ngIf="processing" class="save confirm" disabled>Saving <span class="spinner"></span></button>
        </div>
    </div>
</div>
