<div id="UptimeChart">
    <app-loader [isLoading]="isLoading"></app-loader>
    <div *ngIf="noData" class="noData">No Data</div>
    <div *ngIf="!noData && !isLoading">
        <app-highcharts-generic
            [chartheight]="'20rem'"
            [options]="pie_options"
            [series]="uptime_series"
        ></app-highcharts-generic>
    </div>
</div>
