import {Component, EventEmitter, HostListener, Inject} from '@angular/core';
import {JwtSigner} from "@netfoundry-ui/shared/model";
import {LoggerService} from "@netfoundry-ui/shared/services";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BehaviorSubject} from "rxjs";
import {GrowlerData, GrowlerService} from "@netfoundry-ui/shared/growler";
import _ from 'lodash';

@Component({
    selector: 'app-jwt-signer-form',
    templateUrl: './jwt-signer-form.component.html',
    styleUrls: ['./jwt-signer-form.component.scss'],
})
export class JwtSignerFormComponent {
  processing = false;

  model: JwtSigner;
  isEditing = false;
  hideHelp = false;
  requestUpdate = new EventEmitter<JwtSigner>;
  requestValidate= new EventEmitter<JwtSigner>;
  errorUpdate = new BehaviorSubject<any[]>([]);
  errMap:  any = {};
  values: string[] = [];

  constructor(
    private logger: LoggerService,
    private dialogRef: MatDialogRef<JwtSignerFormComponent>,
    public growlerService: GrowlerService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.errorUpdate.subscribe((errors: any) => {
      this.processing = false;
      if(errors?.length > 0) {
        this.processErrors(errors);
      }
         });
    this.dialogRef.beforeClosed().subscribe(() => {
      this.processing = false;
    });
    this.model = data.model;
    this.isEditing = !!this.model.id;
  }

  save() {
    this.processing = true;
    this.requestUpdate.emit(this.model);
  }

  validate() {
    this.processing = true;
    this.trimWhitespaceScopes();
    this.requestValidate.emit(this.model);
  }

  hide() {
    this.dialogRef.close();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.hide();
  }


  onKeyDown(event: KeyboardEvent) {
    if (event.key === ' ') {
        event.preventDefault();
        const element = event.target as HTMLElement;
        element.blur();
        element.focus();
    }
}

paste(event: ClipboardEvent) {
  event.preventDefault(); 
  const data = event.clipboardData.getData('Text').trim();

  this.model.scopes.push(...data.split(' '));
  this.model.scopes = this.model.scopes.filter(chip => !chip.includes(' '))
  }

  trimWhitespaceScopes() {
    this.model.scopes = this.model.scopes.map(_.trim);
  }

  private processErrors(errors: any) {

    // handle system error
    if(errors[0] ['http'] || errors[0] ['invalid']) {
      this.logger.error('Error from externals-jwt-signers',errors[0]['http']?.message ?? errors[0] ['invalid']);
      this.growlerService.show(
        new GrowlerData(
          'error',
          'JWT Signer update request failed. ',
          errors[0] ['http']?.message ?? errors[0] ['invalid']
        )
      );
    } else {
      this.errMap = {};
      errors.forEach((err: any) => {
        this.errMap[err.label] = err.message;
      })

    }
  }
}
