<div class="fullModal opened" id="AppWANS3Modal">
    <div class="fullRow">
        <div class="sized">
            <div *ngIf="isNotSummary()" class="title stepItem isAt">Create a New S3 Bucket Appwan</div>
            <div *ngIf="step === 5" class="title stepItem isAt" style="margin-bottom: 1.25rem">CONGRATULATIONS</div>

            <div *ngIf="step === 1" class="subtitle smaller stepItem isAt">
                Need to secure and optimize your AWS S3 and other AWS Service access? Then get started by naming your
                app and selecting next.
            </div>
            <div *ngIf="step === 2" class="subtitle smaller stepItem isAt">
                Perfect, now tell us about your<br />current cloud or datacenter.
            </div>
            <div *ngIf="step === 3" class="subtitle smaller stepItem isAt">
                Perfect, we'll set up services<br />based on your buckets location.
            </div>
            <div *ngIf="step === 5" class="subtitle smaller stepItem isAt">
                Your AppWAN has been created!<br />A network summary is below.
            </div>

            <div class="row">
                <div
                    [ngClass]="{
                        slider: hasStepped,
                        isAt: step === 1,
                        passed: isPassedStep(1)
                    }"
                >
                    <div *ngIf="step === 1">
                        <div class="step2Icon"></div>
                        <div class="wizard mini">
                            <div class="header">
                                <div class="darken"></div>
                                <div class="darkenText">What would you like to call your AppWAN?</div>
                            </div>
                            <div class="padded">
                                <label [ngClass]="{ error: form.appWanName.error }" for="AppWANName"
                                    >{{ form.appWanName.label
                                    }}<span [ngClass]="{ error: form.appWanName.error }" class="note"
                                        >Required</span
                                    ></label
                                >
                                <input
                                    [(ngModel)]="form.appWanName.value"
                                    [ngClass]="{ error: form.appWanName.error }"
                                    id="AppWanName"
                                    maxlength="100"
                                    placeholder="{{ form.appWanName.placeholder }}"
                                    type="text"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div [ngClass]="{ isAt: step === 2, passed: isPassedStep(2) }" class="slider">
                    <div *ngIf="step !== 1">
                        <div class="stepAWSIcon"></div>
                        <app-s3-gateway-selector #gatewaySelect [sourceGatewayModel]="sourceGateway" [step]="step">
                        </app-s3-gateway-selector>
                    </div>
                </div>

                <div [ngClass]="{ isAt: step === 3, passed: isPassedStep(3) }" class="slider">
                    <app-s3-service-preselect
                        #serviceSelect
                        *ngIf="step !== 1"
                        [regionId]="regionId"
                        [regions]="regions"
                        [step]="step"
                    >
                    </app-s3-service-preselect>
                </div>

                <div [ngClass]="{ isAt: step === 4, passed: isPassedStep(4) }" class="silder">
                    <app-appwan-wizard-save
                        #saveComponent
                        *ngIf="step !== 1"
                        [appwanModel]="model"
                        [services]="services"
                        [showStep]="4"
                        [sourceGateway]="sourceGateway"
                        [step]="step"
                        [targetGateway]="null"
                    >
                    </app-appwan-wizard-save>
                </div>

                <div [ngClass]="{ isAt: step === 5, passed: isPassedStep(5) }" class="slider">
                    <div *ngIf="step !== 1" class="sized mid">
                        <div class="grid two marginBottom">
                            <div class="hint">
                                <div class="hinttitle">NEW GATEWAYS</div>
                                <div class="hintsubtitle">Tap to Launch and Register</div>
                                <div class="image icon-ClickIcon"></div>
                            </div>
                            <div class="hint">
                                <div class="hinttitle">NEW SERVICES</div>
                                <div class="hintsubtitle">Tap to Edit Advanced Options</div>
                                <div class="image icon-TaptoEditAdvanced"></div>
                            </div>
                        </div>
                    </div>

                    <app-appwan-s3-wizard-summary
                        *ngIf="step !== 1"
                        [finalModel]="finalModel"
                        [ipNetworkServices]="services"
                        [showStep]="5"
                        [step]="step"
                    >
                    </app-appwan-s3-wizard-summary>

                    <div id="LinkButton">
                        <a
                            href="https://support.netfoundry.io/hc/en-us/articles/360041396172-Securing-Your-S3-Bucket-with-NetFoundry-Services"
                            target="_blank"
                            >Further S3 Setup Documentation</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div (click)="close()" class="buttonBall close" id="CloseAppWanWizardButton">
        <div class="buttonLabel">ESC</div>
    </div>
    <!-- <app-form-support-button></app-form-support-button> -->

    <div [ngClass]="{ isAt: isNotSummary() }" class="steps">
        <div (click)="goTo(1)" [ngClass]="{ isAt: step === 1 }" class="step"></div>
        <div (click)="goTo(2)" [ngClass]="{ isAt: step === 2 }" class="step"></div>
        <div (click)="goTo(3)" [ngClass]="{ isAt: step === 3 }" class="step"></div>
        <div (click)="goTo(4)" [ngClass]="{ isAt: step === 4 }" class="step"></div>
    </div>

    <div (click)="back()" [ngClass]="{ isAt: isBetweenSteps(1, 4) }" class="button squared back" id="backButton">
        Back
    </div>
    <div (click)="next()" [ngClass]="{ isAt: isBetweenSteps(0, 4) }" class="button squared next" id="nextButton">
        Next
    </div>
    <div (click)="next()" [ngClass]="{ isAt: step === 3 }" class="button squared next" id="createButton">
        Create My AppWAN
    </div>
</div>
