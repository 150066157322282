<div class="modal solid open" id="ModifyNetworkModal">
    <div (click)="cancel()" class="miniClose cancel icon-HamburgerAppClose"></div>
    <div
        class="title"
        style="
            background-image: url(/assets/svgs/EditNetwork.svg);
            padding-left: 7.5rem;
            background-size: 10%;
            line-height: 4.0625rem;
        "
    >
        MODIFY NETWORK
    </div>

    <div class="label row">
        Network Name<span [ngClass]="{ error: errorNetworkName, note: !errorNetworkName }">{{
            invalidNetworkName ? 'Invalid' : 'Required'
        }}</span>
    </div>
    <div class="row">
        <input
            [(ngModel)]="networkModel.name"
            [ngClass]="{ error: errorNetworkName }"
            id="NetworkName"
            placeholder="Change the Network Name"
        />
    </div>
    <div class="buttons">
        <div (click)="cancel()" class="linkButton cancel">Oops, no get me out of here</div>
        <button (click)="save()" *ngIf="!processing" class="save confirm" id="SaveNetworkNameButton">
            Save Change
        </button>
        <button *ngIf="processing" class="save confirm" disabled>Saving <span class="spinner"></span></button>
    </div>
</div>
