<div *ngIf="networkArea" class="row" id="UsageArea" style="padding-top: 1.25rem">
    <div [chart]="piechart" id="UsageChart"></div>
    <div id="UsageTotal">{{ usageTotal | tobytes }}</div>
    <div id="UsageList">
        <div class="tHeadRow list" style="border-bottom: none">
            <div class="tHead t50">Endpoint</div>
            <div class="tHead t50 tRight">Data <span data-context="UsageType"></span></div>
        </div>
        <div class="usageByGateawy row list" id="UsageListData">
            <div *ngFor="let endpoint of totals; let i = index">
                <div class="tRow" id="vtcRow_{{ endpoint.name }}">
                    <div class="tCol tLeft t50">
                        <span class="bubble highcharts-color-{{ i }}"></span>
                        <span
                            (click)="setEndpointNameFilter(endpoint.name)"
                            [ngStyle]="{
                                'font-weight': endpoint.name == endpointNameFilter ? 'bold' : 'normal'
                            }"
                            >{{ endpoint.name }}</span
                        >
                    </div>
                    <div class="tCol t50 tLeft">{{ endpoint.y | tobytes: true }}</div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loader [isLoading]="networkLoading"></app-loader>
<div *ngIf="noData" class="noData" id="NoUsageArea">No Data</div>
<div *ngIf="!canReadElasticSearch" class="noData" id="NoUsageArea">No Permissions</div>
